@import '../../assets/styles/global.scss';

.control_panel__wrapper {
    width: fit-content;
    background-color: $bg-shadow-color;
    border: none;
    padding: 10px;
    position: fixed;
    right: 50px;
    top: 18%;
    color: $text-color;
    border-radius: 10px;
    display: block;

    &:hover {
        cursor: pointer;
    }

    &:active{
        opacity: 0.6;
    }

    #btn--minimize {
        background: transparent;
        outline: none;
        border: none;
        padding: 0;
        position: relative;
        top: -10px;
        left: 90%;
        font-size: 20px;
        color: rgba($color: #fff, $alpha: .5);
        
        &:hover {
        color: $text-color;
        cursor: pointer;
       }
    }

    
    .control_panel {
        height: 300px;
        display: flex;
        flex-direction: column;
        
        #control_panel__mood-section, #control_panel__effect-section {
            margin: 0;
            
            h4 {
                margin: 0;
                padding-bottom: 15px;
            }
        }
        
        #control_panel__mood-section {
            margin-bottom: 10px;
            #control_panel__mood-list {
                display: flex;
                
                .control_panel__mood-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 68px;
                    height: 68px;
                    margin-left: 10px;
                    border: none;
                    border-radius: 15px;
                    background-color: rgba($color: grey, $alpha: .5);
                    color: rgba($color: #fff, $alpha: .5);
                    font-size: 14px;
                    
                    i {
                        padding-bottom: 5px;
                        font-size: 20px;
                    }
                    
                    &:hover {
                        cursor: pointer;
                    }
                }

                .mood--active {
                    color: $text-color;
                    
                    i {
                        color: $primary-color;
                    }
                    
                }
                
                .control_panel__mood-item:first-child {
                    margin-left: 0;
                }
            }
        }
        
        #control_panel__effect-section {
            overflow-y: scroll;
            
            &::-webkit-scrollbar {
                width: 5px;
            }
            
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: $primary-color;
            }
            
            #control_panel__effect-list {
                display: flex;
                flex-direction: column;
                
                .control_panel__effect-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 0 20px 0;
                    font-size: 16px;
                    
                    h5 {
                        margin: 0;
                    }
                    
                    input[type=range] {
                        cursor: pointer;
                        
                        &::-webkit-slider-runnable-track {
                            background-color: $bg-color;
                            border-radius: 5px;
                            height: 10px;
                        }
                        &::-webkit-slider-thumb {
                            background-color: $primary-color;
                            margin: -3px;
                        }
                        &::-moz-range-thumb {
                            
                            background-color: $primary-color;
                        }
                        
                        &::-ms-thumb {
                            background-color: $primary-color;
                        }
                        
                    }
                    
                }
            }
        }
    }
    
}
.hidden {
    display: none;
}
