@import '../../assets/styles/global.scss';

.time-details__wrapper {
    position: fixed;
    color: $text-color;
    max-width: 20%;
    top: 50%;
    left: 25%;
    font-weight: 400;
    display: flex;
    flex-direction: column;

    .time-details {
        margin-bottom: 15px;
        
    }

    #time-details__greeting {
        font-size: 30px;
        width: 100%;
    }

    #time-details__date {
        font-size: 18px;
        opacity: 0.8;
        font-weight: 300;
    }

    #time-details__anecdote {
        font-size: 15px;
        font-style: italic;
        opacity: 0.7;
        color: black;
        background-color: rgba(250, 250, 250, 0.7);
        padding: 10px;
        border-radius: 5px;
    }

    #time-details__author {
        text-align: right;
        font-weight: 300;
    }
}